.field {
  margin-bottom: 20px !important;
}

.label {
  font-size: 12px !important;
  padding-left: 11px;
}

.input {
  height: 48px !important;
  &.isNotFocused:invalid ~ .errorMessage {
    display: block;
  }
  &.showErrorMessage ~ .errorMessage {
    display: block;
  }
}

.errorMessage {
  display: none;
  position: absolute;
  left: 12px;
  margin-top: 4px;
  color: red;
}

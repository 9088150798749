@import '/src/styles/constants.module.scss';

.boxWithForm {
  z-index: 1;
  margin-top: 20px !important;

  @media screen and (min-width: calc($mobile + 1px)) {
    width: min(815px, 100%);
    position: absolute;
    left: calc(50% - min(407.5px, 50%));
    padding: 50px;
  }

  @media screen and (max-width: $mobile) {
    position: relative;
    margin: auto;
    padding: 30px 10px 40px 10px;
    width: 373px;
    height: 624px;
  }

  @media screen and (min-height: 800px) {
    top: min(15%, 116px);
  }
}

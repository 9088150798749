@import 'src/styles/constants.module';

.boxWithForm {
  z-index: 1;
  margin-top: 20px !important;

  @media screen and (min-width: calc($mobile + 1px)) {
    width: min(815px, 100%);
    position: absolute;
    left: calc(50% - min(407.5px, 50%));
    padding: 50px;
  }

  @media screen and (max-width: $mobile) {
    position: relative;
    margin: auto;
    padding: 30px 10px 40px 10px;
    width: 373px;
    height: 624px;
  }

  @media screen and (min-height: 800px) {
    top: min(15%, 116px);
  }
}

.header {
  width: 210px;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  color: #000000;
}

.field {
  margin-bottom: 20px !important;
}

.label {
  font-size: 12px !important;
  padding-left: 11px;
}

.input {
  height: 48px !important;
  &.isNotFocused:invalid ~ .errorMessage {
    display: block;
  }
  &.showErrorMessage ~ .errorMessage {
    display: block;
  }
}

.stateColumn {
  margin-bottom: 20px !important;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    margin-top: -12px;
  }
}

.pOr {
  text-align: center;
  color: $label-color;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
}

.errorMessage {
  display: none;
  position: absolute;
  left: 12px;
  margin-top: 4px;
  color: red;
}

.blackButton {
  margin-top: 18px;
  //margin-bottom: 40px;
}
